.u-underline {

}

.u-underline--alt {
	position: relative;
	&:before {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		bottom: -.4rem;
		width: 100%;
		height: .3rem;
		background: $c-secondary;
	}
}