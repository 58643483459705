// framed page
.container {
  position: relative;
  max-width: $box-max-width;
  min-height: 100vh;
  margin:0 auto;
  box-shadow:0 0 3.5rem 0 rgba(0, 0, 0, 0.07);
  overflow: hidden;

  @include respond-to(xlarge){
    max-width: $box-max-width;
  }

  &:before {
    position: absolute;
    background-position: center bottom -0.2rem;
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    width: 6rem;
    height: 22rem;
    top: 70%;
    right: -0.2rem;

    @include respond-to(small){
      display: none;
    }
  }

  &--primary {
    background: $c-primary;
    &:before { background-image: url('../img/brand/ornament-5.svg'); }
  }

  &--grey {
    background: $c-grey-1;
    &:before { background-image: url('../img/brand/ornament-4.svg'); }
  }
}

// normal page
.page {
  width: 100%;
  background-color: $page-bg;
}

.main-content {
  outline: none;
  position: relative;
}

@import "grid/settings";
@import "layout/layout";
@import "region/region";
