@font-face {
	font-family: "dvdj_iconfont";
	src: url('/fonts/iconfont/dvdj_iconfont.eot');
	src: url('/fonts/iconfont/dvdj_iconfont.eot?#iefix') format('eot'),
		url('/fonts/iconfont/dvdj_iconfont.woff') format('woff'),
		url('/fonts/iconfont/dvdj_iconfont.ttf') format('truetype'),
		url('/fonts/iconfont/dvdj_iconfont.svg#dvdj_iconfont') format('svg');
}

.dvdj:before {
	font-family: "dvdj_iconfont";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	/* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
	text-decoration: none;
	text-transform: none;
}


.dvdj-caret-down:before {
	content: "\E001";
}

.dvdj-caret-left:before {
	content: "\E002";
}

.dvdj-caret-right:before {
	content: "\E003";
}

.dvdj-cross:before {
	content: "\E004";
}

.dvdj-facebook:before {
	content: "\E005";
}

.dvdj-hamburger:before {
	content: "\E006";
}

.dvdj-instagram:before {
	content: "\E007";
}

.dvdj-snapchat:before {
	content: "\E008";
}

.dvdj-tick:before {
	content: "\E009";
}

.dvdj-twitter:before {
	content: "\E00A";
}
