.company {
  position: relative;
  margin: 3rem 0 0 0;

  &:before {
    position: absolute;
    background: $c-white;
    content: '';
    border-top-right-radius: 100%;
    border-top-left-radius: 100%;
    top: 0;
    right: -16rem;
    bottom: 0rem;
    left: -16rem;
    z-index: 1;
  }

  &:after {
    position: absolute;
    background: $c-primary;
    content: '';
    width: 20rem;
    height: 10rem;
    border-radius: 100%;
    top: -0.8rem;
    right: 20rem;

    @include respond-to(small){
      display: none;
    }
  }

  &__inner {
    position: relative;
    text-align: center;
    transform: translateY(-3rem);
    z-index: 2;

    @include respond-to(small) {
      transform: translateY(-1.4rem);
    }
  }

  &__logo {
    display: block;
    background: $c-secondary;
    max-width: 35rem;
    padding: 1.4rem 2rem 0 2rem;
    margin: 0 auto 2rem auto;

    @include respond-to(small) {
      max-width: 25rem;
      padding: 0.6rem 2rem 0 2rem;
    }

    img {
      position: relative;
      display: inherit;
      width: 100%;
      top: 0.4rem;
    }
  }

  &__newsletter {
    display: block;
    max-width: 35rem;
    margin: 0 auto;
  }
}
