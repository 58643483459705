// Spacing

.u-spacer--small {
  margin-bottom: $spacing-small;
}

.u-spacer--tiny {
  margin-bottom: $spacing-tiny;
}

.u-spacer {
  margin-bottom: $spacing-normal;

  @include respond-to(medium) {
    margin-bottom: 0;
  }
}

.u-spacer--large {
  margin-bottom: $spacing-large;

  @include respond-to(small) {
    margin-bottom: 4rem;
  }
}

.u-spacer--none {
  margin-bottom: 0;
}

.u-space--horizontal + .u-space--horizontal {
  margin-left: $spacing-normal;
}

.u-mobile-spacer {
  @include respond-to(small) {
    margin-bottom: $spacing-small;
  }
}
