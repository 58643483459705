.nav__pages {
  @include respond-to(small) {
    display: none;
  }

  &__list {
    display: flex;
  }

  &__item {
    list-style: none;
    margin: 0 0 0 2rem;
  }

  &__cta {
    position: relative;
    font-size: 1.6rem;
    font-weight: 700;
    color: $c-white;
    text-decoration: none;

    &:hover:before, &:focus:before, &--active:before {
      width: 100% !important;
    }

    &:before {
      position: absolute;
      background: $c-white;
      content: '';
      width: 0;
      height: 0.2rem;
      border-radius: 0.2rem;
      top: -0.6rem;
      left: 0;
      @extend %anim4;
    }
  }
}
