/* ----------------------------------------------------------------------------
 * Structure
 * -- Define structural reset of certain items
 * ------------------------------------------------------------------------- */

// Global greset
* {
  margin: 0;
  padding: 0;
  outline: 0;
}

// global box-sizing
* {
  box-sizing: border-box;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  &:before, &:after {
    box-sizing: inherit;
    font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
  }
}

html {
  font-size: 62.5%;
  min-height: 100%;
}

body {
  position: relative;
  background: $c-white;
  font-family: $f-primary;
  font-size: 1.4rem;
  font-weight: 400;
  color: $c-black;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
