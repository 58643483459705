.tiles__filter {
  height: 4rem;
  margin: 0 0 4rem 0;

  @include respond-to(xsmall) {
    margin: 0 0 2rem 0;
  }

  &__inner {
    position: static;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $c-grey-1;
    text-align: center;
    height: inherit;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10;

    &.stick {
      position: fixed;
    }
  }

  &__title {
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
  }

  &__list {

  }

  &__item {
    display: inline-block;
    list-style: none;
    font-size: 1.8rem;
    font-weight: 700;
    width: 2.4rem;
    height: 2.4rem;
    line-height: 2.4rem;
    text-transform: uppercase;
    cursor: pointer;

    &--active {
      background: $c-secondary;
      color: $c-white;
      border-radius: 100%;
    }
  }
}
