.item {
  width: 24rem;
  padding-top: 2rem;
  padding-bottom: 6rem;
  margin: 0 auto;

  @include respond-to(small) {
    padding: 0;
    margin: 4rem auto 0 auto;
  }

  @include respond-to(xsmall) {
    margin: 2rem auto 0 auto;
  }

  &--offset {
    padding-top: 8rem;
    padding-bottom: 0rem;
  }

  &__cta {
    display: block;
    text-decoration: none;
  }

  &__header {
    position: relative;
  }

  &__image {
    position: relative;
    height: 24rem;
    border-radius: 24rem;
    border: solid 0.4rem $c-white;
    overflow: hidden;
    @extend %anim2;

    .item__cta:hover &, .item__cta:focus & {
      transform: scale(1.04);
    }

    img {
      border-radius: inherit;
      @extend %bg-cover;
    }
  }

  &__tags {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    bottom: 1.6rem;
  }

  &__tag {
    position: relative;
    font-size: 1.3rem;
    font-weight: 500;
    color: $c-white;
    line-height: 2.2rem;
    padding: 0 0.6rem 0.1rem 0.6rem;

    &--type {
      background: $c-secondary;
      padding-right: 0.8rem;
      top: -0.2rem;
      right: -0.2rem;
    }

    &--date {
      background: $c-black;
      padding-left: 0.8rem;
      top: 0.2rem;
      left: -0.2rem;
    }
  }

  &__content {
    text-align: center;
  }

  &__intro {
    font-size: 1.4rem;
    font-weight: 500;
    color: $c-black;
    line-height: 2.2rem;
    margin: 2rem 0;
  }

  &__button {

  }
}
