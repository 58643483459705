.items {
  &--offset {
    position: relative;
    margin: -12rem 0 0 0;
    z-index: 3;

    @include respond-to(small) {
      margin: 0;
    }
  }
}

@import "item";
