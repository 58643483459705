.tiles__separator {
  position: relative;
  margin: 0 0 4rem 0;

  @include respond-to(small) {
    margin: 0 0 2rem 0;
  }

  &:before {
    position: absolute;
    background: $c-white;
    content: '';
    width: 100%;
    top: 1.8rem;
    bottom: 1.8rem;
    z-index: 1.8rem;
  }

  span {
    display: block;
    position: relative;
    background: $c-white;
    font-size: 1.6rem;
    font-weight: 700;
    color: $c-black;
    width: 4rem;
    height: 4rem;
    border-radius: 4rem;
    line-height: 4rem;
    text-align: center;
    text-transform: uppercase;
    z-index: 2;
  }
}
