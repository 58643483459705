.sidebar__pages {
  &__list {

  }

  &__item {
    list-style: none;
    margin: 0 0 1rem 0;

    &:last-child {
      margin: 0;
    }
  }

  &__cta {
    position: relative;
    font-size: 2rem;
    font-weight: 700;
    color: $c-white;
    text-decoration: none;

    &--active:before {
      width: 100% !important;
    }

    &:before {
      position: absolute;
      background: $c-white;
      content: '';
      width: 0;
      height: 0.2rem;
      border-radius: 0.2rem;
      top: 1.4rem;
      left: 0;
      @extend %anim4;
    }
  }
}
