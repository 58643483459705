/*
General layout width etc.
*/

.layout {
  margin: 0 auto;
  position: relative;
  @extend %clearfix;
  outline: 0;
}

@import "small";
@import "normal";
@import "wide";

.layout--full-s {
  @include respond-to(small) {
    padding: 0;
  }
}
