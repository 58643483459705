@import "grid";

$grid-cols: 12;
$small-gutter: 1.5rem;
$large-gutter: 3rem;

$grid_args:(
  config: (
    gridclass:           'grid',
    itemclass:           'col--',
    pushclass:           'push--',
    push:                true,
    debug:               false,
    classdebug:          false,
    ie9:                  true
  ),
  grids: (
    default: (
      columns:          $grid-cols,
      gutter:           $large-gutter,
      debug_name:       'default',
      debug_bg:           #f175c8,
    ),
    large: (
      columns:          $grid-cols,
      suffix:           '--l',
      breakpoint_min:   $bp-medium + 1,
      //      breakpoint_max:   null,
      gutter:           $large-gutter,
      debug_name:       'Large',
      debug_bg:         #1a4cf1,
    ),
    xlarge: (
      columns:          $grid-cols,
      suffix:           '--xl',
      breakpoint_min:   $bp-large + 1,
      //      breakpoint_max:   null,
      gutter:           $large-gutter,
      debug_name:       'X-Large',
      debug_bg:         #ea121d,
    ),
    medium: (
      columns:          $grid-cols,
      suffix:           '--m',
      //      breakpoint_min:   null,
      breakpoint_max:   $bp-medium,
      gutter:           $large-gutter,
      debug_name:       'Medium',
      debug_bg:         #0c9c2e,
    ),
    small: (
      columns:          $grid-cols,
      suffix:           '--s',
      //      breakpoint_min:   null,
      breakpoint_max:   $bp-small,
      gutter:           $small-gutter,
      debug_name:       'Small',
      debug_bg:         #ea8d35,
    ),
    xsmall: (
      columns:          $grid-cols,
      suffix:           '--xs',
//      breakpoint_min:   null,
      breakpoint_max:   $bp-xsmall,
      gutter:           $small-gutter,
      debug_name:       'X-Small',
      debug_bg:         #eac11f,
    )
  )
);

//grid fix
.col--flex > .grid {
  min-width: 100%;
}

// IE10 and lower
.no-flexbox .grid {
  display: block !important;
  min-width: 0;
}

// IE11 and UP
// _:-ms-fullscreen, :root .grid {
//   min-width: calc(100% + 3rem);
// }

// Grid
//
// 12 col grid
//
// Markup: grid.html
//
// Styleguide grid

// Instantiate Gritacular grid
// using the above options
@include grid_generate($grid_args);


$form_grid_args:(
  config: (
    gridclass:           'form-grid',
    itemclass:           'form-col--',
    pushclass:           'form-push--',
    push:                true,
    debug:               false,
    classdebug:          false,
    ie9:                  true
  ),
  grids: (
    default: (
      columns:          $grid-cols,
      gutter:           $small-gutter,
      debug_name:       'default',
      debug_bg:           #f175c8,
    ),
    small: (
      columns:          $grid-cols,
      suffix:           '--s',
      //      breakpoint_min:   null,
      breakpoint_max:   $bp-small,
      gutter:           $small-gutter,
      debug_name:       'Small',
      debug_bg:         #ea8d35,
    )
  )
);

// Instantiate Gritacular grid
// using the above options
@include grid_generate($form_grid_args);

$span_grid_args:(
  config: (
    gridclass:           'span-grid',
    itemclass:           'span--',
    pushclass:           'push--',
    push:                true,
    debug:               false,
    classdebug:          false,
    ie9:                  true
  ),
  grids: (
    default: (
      columns:          $grid-cols,
      gutter:           0rem,
      debug_name:       'default',
      debug_bg:           #f175c8,
    ),
    medium: (
      columns:          $grid-cols,
      suffix:           '--m',
      //      breakpoint_min:   null,
      breakpoint_max:   $bp-medium,
      gutter:           0rem,
      debug_name:       'Medium',
      debug_bg:         #0c9c2e,
    ),
    small: (
      columns:          $grid-cols,
      suffix:           '--s',
      //      breakpoint_min:   null,
      breakpoint_max:   $bp-small,
      gutter:           0rem,
      debug_name:       'Small',
      debug_bg:         #ea8d35,
    )
  )
);

// Instantiate Gritacular grid
// using the above options
@include grid_generate($span_grid_args);
