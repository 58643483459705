// Align
//
// Align utilities
//
// Markup: align.html
//
// Styleguide utilities.align.align

.u-align-left {
  text-align: left !important;
}

.u-align-center {
  text-align: center !important;
}

.u-align-right {
  text-align: right !important;
}

@each $name, $align in $bp-list {
  @include respond-to(map-get($align, media)){
    .u-align-left--#{$name} {
      text-align: left !important;
    }
    .u-align-center--#{$name} {
      text-align: center !important;
    }
    .u-align-right--#{$name} {
      text-align: right !important;
    }
  }
}
