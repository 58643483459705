.link {
  position: relative;
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 500;
  text-decoration: none;

  &:before {
    position: absolute;
    background: $c-secondary;
    content: '';
    width: 100%;
    height: 0.2rem;
    border-radius: 0.2rem;
    bottom: -0.4rem;
    @extend %anim2;
  }

  &:hover:before, &:focus:before {
    width: 110%;
  }

  // styles primary

  &--primary {
    color: $c-primary;
  }

  // styles secondary

  &--secondary {
    color: $c-secondary;
  }

  // styles white

  &--white {
    color: $c-white;
  }
}
