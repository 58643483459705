.info {
  position: relative;

  &__tags {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    top: -10rem;
    z-index: 4;

    @include respond-to(small) {
      top: -5.8rem;
    }

    @include respond-to(xsmall) {
      top: -3.8rem;
    }
  }

  &__tag {
    position: relative;
    font-size: 1.3rem;
    font-weight: 500;
    color: $c-white;
    line-height: 2.2rem;
    padding: 0 0.6rem 0.1rem 0.6rem;

    &--type {
      background: $c-secondary;
      padding-right: 0.8rem;
      top: -0.2rem;
      right: -0.2rem;
    }

    &--date {
      background: $c-black;
      padding-left: 0.8rem;
      top: 0.2rem;
      left: -0.2rem;
    }
  }

  &__typography {
    margin: 0 0 6rem 0;

    @include respond-to(small) {
      margin: 0 0 4rem 0;
    }

    @include respond-to(xsmall) {
      margin: 0 0 2rem 0;
    }

    > *:not(img) {
      max-width: 80%;
      margin: 0 auto;

      @include respond-to(small) {
        max-width: 100%;
      }
    }
  }

  &__share {
    display: flex;
    align-items: center;
    max-width: 80%;
    margin: 0 auto;

    @include respond-to(small) {
      max-width: 100%;
    }

    &__title {
      font-size: 1.5rem;
      font-weight: 500;
      color: $c-black;
      line-height: 2.4rem;
    }

    &__list {
      display: flex;
    }

    &__item {
      list-style: none;
      margin: 0 0 0 1.8rem;
    }

    &__cta {
      display: block;
      font-size: 1.8rem;
      color: $c-white;
      width: 4rem;
      height: 4rem;
      line-height: 4rem;
      text-align: center;
      text-decoration: none;
      box-shadow: 0 0 2rem 0 rgba(0,0,0,0.08);
      @extend %anim2;

      &:hover, &:focus {
        transform: scale(1.04);
      }

      .info__share__item:nth-child(1) & { background: $c-facebook; }
      .info__share__item:nth-child(2) & { background: $c-twitter; }
    }
  }
}
