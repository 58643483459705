.hero {
  position: relative;
  height: 100vh;
  max-height: 100rem;

  @include respond-to(small) {
    height: 20rem;
  }

  &__image {
    position: absolute;
    @extend %abs-full;

    img {
      @extend %bg-cover;
    }
  }

  &__inner {
    position: relative;
    height: inherit;
    max-height: inherit;
    z-index: 2;
  }
}

@import "hero-content";
@import "hero-timer";
@import "hero-footer";
