.text-image-teaser {
  background: $c-white;

  &__inner {
    display: flex;
    align-items: center;

    @include respond-to(small) {
      display: initial;
      text-align: center;
      max-width: 40rem;
      margin: 0 auto;
    }
  }

  &__image {
    overflow: hidden;
    border-bottom-right-radius: 1rem;
    box-shadow: 0 0 2rem 0 rgba(0,0,0,0.08);

    img {
      display: inherit;
      width: 100%;
    }
  }

  &__content {

  }

  &__title {
    font-family: $f-secondary;
    font-size: 4.8rem;
    font-weight: 700;
    line-height: 4.8rem;
    text-transform: uppercase;

    @include respond-to(small) {
      margin: 4rem 0 0 0;
    }
  }

  &__typography {
    margin: 4rem 0 0 0;

    @include respond-to(small) {
      margin: 2rem 0 0 0;
    }

    p {
      font-size: 1.4rem;
      font-weight: 500;
      color: $c-black;
      line-height: 2.2rem;
    }
  }

  &__button {
    margin: 5rem 0 0 0;

    @include respond-to(small) {
      margin: 3rem 0 0 0;
    }
  }
}

@import "ornament";
