.nav__extra {
  position: absolute;
  right: 3rem;
  bottom: -2.2rem;

  @include respond-to(small) {
    display: none;
  }

  &__list {
    display: flex;
  }

  &__item {
    list-style: none;
    box-shadow: 0 0 2rem 0 rgba(0,0,0,0.08);
    margin: 0 0 0 0.4rem;
  }

  &__cta {

  }
}
