@import "iconfont/iconfont";

/*.u-pagetitle {

}*/

/*.u-title {

}*/

/*.u-subtitle {

}*/

.u-typography {
  // h1 {
  //   display: block;
  //   font-size: 1.5rem;
  //   font-weight: 700;
  //   color: $c-grey-3;
  //   line-height: 2.4rem;
  // }

  p {
    display: block;
    font-size: 1.5rem;
    font-weight: 400;
    color: $c-grey-3;
    line-height: 2.4rem;
  }

  strong {
    font-weight: 700;
  }

  span {
    display: block;
    font-size: 1.3rem;
    font-weight: 500;
    color: $c-grey-3;
    line-height: 2.4rem;
    text-align: center;
  }

  a {
    font-weight: 700;
    color: $c-secondary;
    text-decoration-skip: ink;
  }

  img {
    width: 100%;
    box-shadow: 0 0 2rem 0 rgba(0,0,0,0.2);
  }

  > *:not(:last-child) {
    margin-bottom: 2.8rem;

    @include respond-to(small) {
      margin-bottom: 1.8rem;
    }
  }
}
