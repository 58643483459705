.contact {
  &__content {
    text-align: center;
  }

  &__form {
    &--offset {
      margin: 6rem 0;

      @include respond-to(small) {
        margin: 4rem 0;
      }

      @include respond-to(xsmall) {
        margin: 2rem 0;
      }
    }
  }

  &__title {
    font-size: 2rem;
    font-weight: 700;
    color: $c-grey-3;
    line-height: 2.8rem;
    margin: 0 0 2.4rem 0;

    @include respond-to(small) {
      margin: 0;
    }
  }

  &__item {
    font-size: 1.5rem;
    font-weight: 700;
    color: $c-grey-3;
    line-height: 2.4rem;
    list-style: none;
    margin: 0 0 0.4rem 0;

    @include respond-to(small) {
      margin: 0 0 0.2rem !important;
    }

    &:last-child {
      margin: 0;
    }

    &--spacer {
      margin: 0 0 2.4rem 0;
    }
  }
}
