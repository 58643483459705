.hero__timer {
  text-align: center;
  margin: -10rem 0 0 0;

  @include respond-to(small) {
    margin: 5rem 0 0 0;
  }

  // plain modifier

  &--plain .hero__timer__unit {
    color: $c-white;
    padding: 1rem 1rem 0 1rem;

    &--primary { background: $c-primary; }
    &--secondary { background: $c-secondary; }
  }

  // line modifier

  &--line .hero__timer__unit {
    color: $c-white;
    margin: 0 1rem;

    &--primary { border-bottom: solid 0.4rem $c-primary; }
    &--secondary { border-bottom: solid 0.4rem $c-secondary; }
  }

  &__unit {
    display: inline-block;
    font-family: $f-secondary;
    font-size: 8rem;
    line-height: 7rem;

    @include respond-to(small) {
      font-size: 4rem;
      line-height: 4rem;
      padding: 0.6rem 1rem 0 1rem;
    }

    i {
      font-style: normal;
    }
  }
}
