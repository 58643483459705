.content {
  position: relative;
	width: 100%;
	height: 100%;
  overflow: hidden;
  overflow-y: scroll;
	transition: transform 0.4s;

  .show-sidebar & {
    transition-delay: 0.2s;
    transform: translate3d(10rem,0,0);
  }

  &:before {
    position: absolute;
    background: rgba($c-black,0.6);
    content: '';
    width: 100%;
    height: 100%;
    opacity: 0;
    top: 0;
    left: 0;
    z-index: 1000;
    transition: opacity 0.4s, transform 0s 0.4s;
    transform: translate3d(100%,0,0);

    .show-sidebar & {
      opacity: 1;
      transition: opacity 0.4s;
      transform: translate3d(0,0,0);
    }
  }
}
