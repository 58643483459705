.text-teaser {

  // modifiers

  &--1 {
    .text-teaser__title:before { background: $c-secondary; }
    .text-teaser__title span { color: $c-white; }
    .text-teaser__intro { color: $c-white; }
  }

  &--2 {
    .text-teaser__title:before { background: $c-white; }
    .text-teaser__title span { color: $c-black; }
    .text-teaser__intro { color: $c-white; }
  }

  &--3 {
    .text-teaser__title:before { background: $c-primary; }
    .text-teaser__title span { color: $c-black; }
    .text-teaser__intro { color: $c-black; }
  }

  &__content {
    text-align: center;
    max-width: 60rem;
    margin: 0 auto;
  }

  &__quote {
    position: relative;

    &:before {
      position: absolute;
      content: '\201C';
      font-size: 9rem;
      font-weight: 700;
      color: darken($c-primary, 6%);
      line-height: 0;
      top: 2.8rem;
      left: 1rem;
      z-index: 1;

      @include respond-to(xsmall) {
        left: 2rem;
      }
    }

    span {
      position: relative;
      font-size: 2rem;
      font-weight: 700;
      color: $c-white;
      line-height: 2.8rem;
      z-index: 2;
    }

    strong {
      background: $c-secondary;
      padding: 0 0.2rem 0.2rem 0.2rem;
    }
  }

  &__title {
    position: relative;
    display: inline-block;

    &:before {
      position: absolute;
      content: '';
      height: 0.4rem;
      border-radius: 0.4rem;
      top: 2.6rem;
      right: -2rem;
      left: -2rem;
      z-index: 1;

      @include respond-to(xsmall) {
        top: 1.4rem;
        right: -0.2rem;
        left: -0.2rem;
      }
     }

    span {
      position: relative;
      font-size: 3.8rem;
      font-weight: 700;
      line-height: 4.8rem;
      z-index: 2;

      @include respond-to(xsmall) {
        font-size: 2.8rem;
        line-height: 2.8rem;
      }
    }
  }

  &__intro {
    font-size: 1.4rem;
    font-weight: 500;
    color: $c-white;
    line-height: 2.2rem;
    margin: 2rem 0 3rem 0;
  }

  &__button {

  }
}
