.text-image-teaser__ornament {
  position: relative;
  background: $c-primary;
  width: 100%;
  height: 8rem;
  overflow: hidden;

  &:before {
    position: absolute;
    background: $c-white;
    content: '';
    height: 16rem;
    border-radius: 100%;
    right: -16rem;
    left: -16rem;
    z-index: 2;
  }

  &:after {
    position: absolute;
    background: $c-secondary;
    content: '';
    z-index: 1;
  }

  // ornament top

  &:first-child {
    &:before {
      top: 4rem;
    }

    &:after {
      width: 20rem;
      height: 16rem;
      border-top-left-radius: 6rem;
      border-top-right-radius: 16rem;
      top: 0;
      right: -10rem;
    }
  }

  // ornament bottom

  &:last-child {
    &:before {
      bottom: 4rem;
    }

    &:after {
      width: 40rem;
      height: 40rem;
      border-radius: 40rem;
      bottom: 0;
      left: -20rem;
    }
  }
}
