.social {
  position: fixed;
  bottom: 8rem;
  right: 0;
  z-index: 800;

  @include respond-to(small) {
    display: none;
  }

  &__list {
    box-shadow: 0 0 2rem 0 rgba(0,0,0,0.08);
  }

  &__item {
    color: $c-white;
    width: 4rem;
    min-height: 3.8rem;
    line-height: 3.8rem;
    text-align: center;
    list-style: none;
    padding: 0.2rem 0;
    margin: 0 0 0.4rem 0;

    &:last-child {
      margin: 0;
    }

    &:nth-child(1) { background: $c-facebook; }
    &:nth-child(1) i { font-size: 1.8rem; }

    &:nth-child(2) { background: $c-twitter; line-height: 6rem; }
    &:nth-child(2) a { position: relative; transform: rotate(90deg); top: -0.4rem; }

    &:nth-child(3) { background: $c-instagram; }
    &:nth-child(3) i { font-size: 2rem; }

    &:nth-child(4) { background: $c-snapchat; }
    &:nth-child(4) i { font-size: 2rem; }
  }

  &__cta {
    display: block;
    color: inherit;
    text-decoration: none;
    @extend %anim2;

    &:hover, &:focus {
      padding: 0.6rem 0;
    }

    i {
      position: relative;
      top: 0.2rem;
    }

    small {
      font-family: $f-secondary;
      font-size: 1.8rem;
    }
  }
}
