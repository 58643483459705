.post {
  position: relative;
  background: $c-black;
  width: 15%;
  height: 18rem;
  margin: 0 0.8rem 0.8rem 0.8rem;

  @include respond-to(medium) {
    width: 30%;
    height: 22rem;
  }

  @include respond-to(small) {
    width: 45%;
  }

  @include respond-to(xsmall) {
    height: 16rem;
  }

  &__cta {
    display: block;
    width: 100%;
    height: 100%;
  }

  &__image {
    position: relative;
    width: 100%;
    height: 100%;
    @extend %anim2;

    .post__cta:hover &, .post__cta:focus & {
      opacity: 0.6;
    }

    img {
      @extend %bg-cover;
    }
  }

  &__message {
    position: absolute;
    font-size: 1.2rem;
    font-weight: 500;
    color: $c-white;
    line-height: 1.6rem;
    bottom: 1rem;
    left: 1rem;
  }
}
