.nav {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;

  @include respond-to(small) {
    .layout {
      padding: 0;
    }
  }

  &__inner {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    background: $c-secondary;
    height: 7rem;
    box-shadow: 0 0 2rem 0 rgba(0,0,0,0.08);
    padding: 0 3rem;

    @include respond-to(small) {
      height: 5rem;
      padding: 0 2rem;
    }
  }

  &__logo {
    position: relative;
    width: 22rem;
    top: 0.8rem;

    img {
      display: inherit;
      width: 100%;
    }
  }
}

@import "nav-pages";
@import "nav-extra";
@import "nav-hamburger";
