.disclaimer__share {
  &__list {
    display: flex;

    @include respond-to(small) {
      justify-content: center;
    }
  }

  &__item {
    position: relative;
    font-size: 1.2rem;
    color: $c-white;
    width: 3.6rem;
    height: 3.8rem;
    list-style: none;
    text-align: center;
    box-shadow: 0 0 2rem 0 rgba(0,0,0,0.08);
    margin: 0 0.4rem 0 0;
    top: 0;
    @extend %anim2;

    &:hover, &:focus {
      top: 1rem;

      @include respond-to(small) {
        top: 0;
        transform: scale(1.04);
      }
    }

    &:before {
      position: absolute;
      background: inherit;
      content: '';
      width: inherit;
      height: 2rem;
      top: 100%;
      left: 0;

      @include respond-to(small) {
        display: none;
      }
    }

    &:nth-child(1) { background: $c-facebook; }
    &:nth-child(2) { background: $c-twitter; }
    &:nth-child(3) { background: $c-instagram; }
    &:nth-child(4) { background: $c-snapchat; }
  }

  &__cta {
    display: block;
    color: inherit;
    height: inherit;
    text-decoration: none;

    i {
      position: relative;
      font-size: 1.8rem;
      top: 0.8rem;
    }
  }
}
