.sidebar {
	position: fixed;
	width: 30rem;
	height: 100vh;
  z-index: 1001;
  transition: transform 0.4s;
	transform: translate3d(-30rem,0,0);

  .show-sidebar & {
  	transform: translate3d(0,0,0);
  }

  &__inner {
		display: flex;
		align-items: center;
    background: $c-primary;
  	width: calc(100% - 12rem);
  	height: inherit;
		padding: 4rem;
  }

	&__close {
		position: absolute;
		background: none;
		border: none;
		top: 4rem;
		left: 4rem;

		i {
			font-size: 2rem;
			color: $c-white;
		}
	}

	&__extra {
		position: absolute;
		bottom: 4rem;
		left: 4rem;
	}
}

@import "content";
@import "shape";
@import "sidebar-pages";
