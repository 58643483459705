// Positioning
//
// Positioning utilities
//
// Markup: positioning.html
//
// Styleguide utilities.positioning

// Positioning

.u-position-absolute {
  position: absolute !important;
}

.u-position-relative {
  position: relative !important;
}

.u-float-right {
  float: right !important;
}

.u-float-left {
  float: left !important;
}

.u-float-none {
  float: none !important;
}

.u-display-inline-block {
  display: inline-block !important;
}

.u-clearfix {
  @extend %clearfix;
}

.u-no-overflow {
  overflow: hidden;
}

.u-named-anchor {
  &:before {
    content: '';
    display: block;
    height: 90px;
    margin: -90px 0 0;
    z-index: -1;
    position: relative;
  }
}
