// Responsive
//
// Responsive utilities
//
// Markup: responsive.html
//
// Styleguide utilities.respsonsive

// hidden on mobile
.u-hidden-mobile {
  @include respond-to(small) {
    display: none !important;
  }
}

// visible on mobile
.u-visible-mobile {
  display: none;
  @include respond-to(small) {
    display: block;
  }
}
