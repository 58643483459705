.pagination {
  position: relative;
  margin: 10rem 0 0 0;

  @include respond-to(small) {
    margin: 4rem 0 0 0;
  }

  @include respond-to(xsmall) {
    margin: 2rem 0 0 0;
  }

  &:before {
    position: absolute;
    background: lighten($c-primary, 8%);
    content: '';
    width: 200rem;
    height: 0.4rem;
    margin: auto 0;
    top: 0;
    bottom: 0;
    left: -50rem;
    z-index: 1;
  }

  &__list {
    position: relative;
    text-align: center;
    z-index: 2;
  }

  &__item {
    display: inline-block;
    list-style: none;
    margin: 0 0.4rem;
  }

  &__cta {
    display: block;
    background: $c-white;
    font-size: 2.4rem;
    font-weight: 500;
    color: $c-black;
    width: 5.4rem;
    height: 5.4rem;
    line-height: 5.4rem;
    border-radius: 5.4rem;
    text-decoration: none;
    @extend %anim2;

    &:hover, &focus, &--active {
      background: $c-secondary;
      color: $c-white;
    }

    .icon {
      position: relative;
      font-size: 2.4rem;
      top: -0.2rem;
    }
  }
}
