.toolbox {
  &__content {
    text-align: center;
    margin: 0 0 4rem 0;

    @include respond-to(small) {
      margin: 0 0 2rem 0;
    }

    @include respond-to(xsmall) {
      margin: 0;
    }
  }
}
