.tile {
  background: $c-white;
  max-width: 30rem;
  min-height: 42rem;
  border-bottom-right-radius: 1rem;
  box-shadow: 0 0 2rem 0 rgba(0,0,0,0.08);
  margin: 0 auto;

  @include respond-to(small) {
    margin: 0 auto 2rem auto !important;
  }

  &--1 { margin-top: 0; }
  &--2 { margin-top: 2rem; }
  &--3 { margin-top: 4rem; }

  &--map {
    opacity: 0;
  }

  &__cta {
    position: relative;
    display: block;
    min-height: inherit;
    text-decoration: none;
  }

  &__header {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 16rem;
    z-index: 1;
  }

  &__content {
    position: relative;
    padding: 2rem;
    margin: -3.2rem 0 0 0;
    z-index: 2;
  }

  &__image {
    img {
      @extend %bg-cover;
      @extend %anim2;

      .tile__cta:hover &, .tile__cta:focus & {
        transform: scale(1.04);
      }
    }
  }

  &__title {
    position: absolute;
    font-family: $f-secondary;
    font-size: 2.4rem;
    font-weight: 700;
    color: $c-white;
    line-height: 2.2rem;
    text-transform: uppercase;
    bottom: 2rem;
    left: 2rem;
  }

  &__time {
    display: inline-block;
    background: $c-secondary;
    font-size: 1.2rem;
    font-weight: 500;
    color: $c-white;
    padding: 0.4rem 0.8rem;
    margin: 0 0 0.4rem 0;
  }

  &__location {
    display: inline-block;
    background: $c-primary;
    font-size: 1.2rem;
    font-weight: 500;
    color: $c-white;
    padding: 0.4rem 0.8rem;
    margin: 0 0 1rem 0;
  }

  &__intro {
    font-size: 1.4rem;
    font-weight: 500;
    color: $c-black;
    line-height: 2.2rem;
    padding: 0 0 4rem 0;
  }

  &__link {
    // display: none;
    position: absolute;
    bottom: 2.4rem;
    left: 2rem;
  }
}
