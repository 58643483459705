/*
Small layout variant
*/

.layout--small {
  width: 60%;
  min-width: $page-min-width;
  max-width: $page-max-width;
  padding: 0 $large-gutter;

  // small screens
  @include respond-to(small) {
    width: auto;
    min-width: 0;
    padding: 0 $small-gutter;
  }
}
