.location {
  position: relative;
  margin: -6rem 0 0 0;
  z-index: 3;

  @include respond-to(small) {
    margin: 4rem 0 0 0;
  }

  @include respond-to(xsmall) {
    margin: 2rem 0 0 0;
  }

  &__map {
    background: $c-grey-2;
    width: 100%;
    height: 48rem;
    border-bottom-right-radius: 1rem;
    box-shadow: 0px 0px 4rem 0px rgba(0,0,0,0.08);
    border: solid 0.8rem $c-white;
    margin: 0 0 4rem 0;

    @include respond-to(small) {
      height: 20rem;
    }

    @include respond-to(xsmall) {
      margin: 0 0 2rem 0;
    }
  }

  &__item {
    position: relative;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2.4rem;
    color: $c-black;
    list-style: none;
    margin: 0 0 1.4rem 0;

    @include respond-to(small) {
      font-size: 1.4rem;
      margin: 0;
    }

    &:last-child {
      margin: 0;
    }

    &:before {
      position: absolute;
      background: $c-primary;
      content: '';
      width: 100rem;
      height: 0.4rem;
      border-radius: 0.4rem;
      top: 0.8rem;
      right: 120%;
    }

    &--spacer {
      margin: 0 0 4rem 0;
    }

    a {
      color: inherit;
      text-decoration-skip: ink;

      &:hover, &:focus {
        text-decoration: none;
      }
    }
  }
}
