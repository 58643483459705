.disclaimer {
  position: relative;
  background: $c-white;
  padding: 0 2rem 2rem 2rem;
  z-index: 1;

  &__inner {
    > * {
      display: inline-block;
      vertical-align: middle;

      @include respond-to(small) {
        display: block;
        width: 100% !important;
        text-align: center !important;
      }

      &:nth-child(1) { width: 20%; text-align: left; }
      &:nth-child(2) { width: 58%; text-align: center; }
      &:nth-child(3) { width: 20%; text-align: right; }
    }
  }

  &__legal {
    font-size: 1.2rem;
    font-weight: 400;
    color: $c-black;

    @include respond-to(small) {
      margin: 1.4rem 0;
    }

    a {
      color: inherit;
    }
  }

  &__logo {
    img {
      width: 3.2rem;
    }
  }
}

@import "disclaimer-share";
