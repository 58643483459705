.feed {
  &__inner {
    position: relative;

    &:before {
      position: absolute;
      background: $c-white;
      content: '';
      width: 200rem;
      height: 0.4rem;
      bottom: 2rem;
      left: -50rem;
    }
  }

  &__title {
    position: absolute;
    background: $c-white;
    font-size: 1.8rem;
    font-weight: 700;
    color: $c-black;
    box-shadow: 0 0 2rem 0 rgba(0,0,0,0.08);
    padding: 1rem 1.4rem;
    top: -2rem;
    left: 4rem;
    z-index: 2;
  }

  &__posts {
    display: flex;
    flex-wrap: wrap;
    // flex-wrap: nowrap;
    justify-content: space-around;
  }
}

@import "post";
