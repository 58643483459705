.form {
  &__inner {
    background: $c-white;
    box-shadow: 0 0 2rem 0 rgba(0,0,0,0.08);
    padding: 2.8rem 4rem;

    @include respond-to(small) {
      padding: 2.8rem;
    }
  }

  &__field {
    margin: 0 0 2rem 0;

    @include respond-to(small) {
      &:not(:nth-last-child(1)) {
        margin: 0 0 2rem 0 !important;
      }
    }

    &:nth-last-child(1),
    &:nth-last-child(2) {
      margin: 0;
    }
  }

  &__label {
    display: block;
    font-size: 1.4rem;
    font-weight: 700;
    color: $c-grey-3;
    margin: 0 0 0.4rem 0;
  }

  &__error {
    display: block;
    font-size: 1.2rem;
    font-weight: 500;
    color: $c-secondary;
    margin: 0.4rem 0 0 0;
  }

  &__input,
  &__textarea {
    display: block;
    font-family: $f-primary;
    font-size: 1.4rem;
    font-weight: 400;
    color: $c-grey-3;
    width: 100%;
    border: solid 0.1rem $c-grey-2;
    @extend %anim2;

    &:focus {
      border: solid 0.1rem $c-primary;
    }
  }

  &__input {
    line-height: 4.6rem;
    padding: 0 2rem;
  }

  &__textarea {
    height: 20rem;
    padding: 2rem;
    resize: none;
  }

  &__submit {
    transform: translateY(4.4rem);

    @include respond-to(small) {
      transform: translateY(0);
    }
  }
}
