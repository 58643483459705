/* ----------------------------------------------------------------------------
 * Mixins
 * ------------------------------------------------------------------------- */

/**
*
* General items
*
**/

// clearfix
%clearfix, .clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

// Absolute element top-left-right-bottom
%abs-full{
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

// Simulate "background-size: cover" on normal image
%bg-cover{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
// %bg-cover{
//   position: absolute;
//   top: -9999px;
//   right: -9999px;
//   bottom: -9999px;
//   left: -9999px;
//   margin: auto;
//   min-width: 100%;
//   min-height: 100%;
//   width: auto;
//   height: auto;
// }


.objectfit{
  %bg-cover{
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
}

// Ellips text
// -- Width of the text needs to be defined on the element
%text-ellipsis{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/**
*
* Responsive queries
*
**/

// responsiveness
@mixin respond-to($media) {
  @if $media == xsmall {
    @media screen and (max-width: $bp-xsmall) {
      @content;
    }
  } @else if $media == small {
    @media screen and (max-width: $bp-small) {
      @content;
    }
  } @else if $media == medium {
    @media screen and (max-width: $bp-medium) {
      @content;
    }
  } @else if $media == medium-up {
    @media screen and (min-width: $bp-small + 0.1) {
      @content;
    }
  } @else if $media == large {
    @media screen and (min-width: $bp-medium + 0.1) {
      @content;
    }
  } @else if $media == xlarge {
    @media screen and (min-width: $bp-large + 0.1) {
      @content;
    }
  } @else if $media == jump-narrow {
    @media screen and (max-width: $breakpoint-jump-narrow) {
      @content;
    }
  } @else if $media == screenreader {
    @media reader, speech, aural {
      @content;
    }
  } @else {
    @content;
  }
}

#hidden, .hidden {
  display: none !important;
}
