.file {
  width: 33%;
  min-width: 28rem;
  padding: 2rem 1rem 0 1rem;
  margin: 0 auto;

  @include respond-to(small) {
    min-width: 32rem;
  }

  &__inner {
    height: 28rem;
    @extend %anim2;

    &:hover, &:focus {
      transform: scale(1.04);
    }

    .file:nth-child(even) & {
      border: solid 0.1rem $c-primary;
    }

    .file:nth-child(odd) & {
      background: $c-white;
      box-shadow: 0 0 2rem 0 rgba(0,0,0,0.08);
    }
  }

  &__cta {
    position: relative;
    display: block;
    height: inherit;
    text-decoration: none;
    padding: 2.8rem;
  }

  &__title {
    font-size: 1.8rem;
    font-weight: 700;
    color: $c-grey-3;
    line-height: 2rem;
    margin: 0 0 1rem 0;
  }

  &__intro {
    font-size: 1.4rem;
    font-weight: 400;
    color: $c-grey-3;
    line-height: 2.4rem;
  }

  &__link {
    position: absolute;
    bottom: 3.2rem;
    left: 2.8rem;
  }
}
