.jumbotron {
  position: relative;
  height: 50vh;
  max-height: 50rem;

  @include respond-to(small) {
    height: 20rem;
  }

  &--primary {
    &:before { background-image: url('../img/brand/ornament-2.svg'); }
    .jumbotron__inner { background: rgba($c-primary, 0.9); }
    .jumbotron__title { color: $c-white; }
    .jumbotron__subtitle { color: $c-white; }
  }

  &--white {
    &:before { background-image: url('../img/brand/ornament-3.svg'); }
    .jumbotron__inner { background: rgba($c-white, 0.9); }
    .jumbotron__title { color: $c-black; }
    .jumbotron__subtitle { color: $c-black; }
  }

  &--news {
    &.jumbotron--primary .jumbotron__inner { background: rgba($c-primary, 0.7); }
    &.jumbotron--white .jumbotron__inner { background: rgba($c-white, 0.7); }

    .jumbotron__title {
      font-size: 2.8rem;
      line-height: 3.2rem;
    }
  }

  &:before {
    position: absolute;
    background-position: center bottom -0.2rem;
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    height: 16rem;
    right: -1rem;
    bottom: 0;
    left: -1rem;
    z-index: 2;
  }

  &__image {
    position: absolute;
    @extend %abs-full;

    img {
      @extend %bg-cover;
    }
  }

  &__inner {
    position: relative;
    height: inherit;
    max-height: inherit;
    z-index: 1;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: inherit;
  }

  &__title {
    font-size: 4rem;
    font-weight: 700;
    line-height: 4.2rem;
    text-align: center;
    max-width: 80rem;
    margin: 4rem auto 0 auto;

    @include respond-to(small) {
      font-size: 2.2rem !important;
      line-height: 2.2rem !important;
    }
  }

  &__subtitle {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2rem;
    text-align: center;
    max-width: 80rem;
    margin: 0.8rem auto 0 auto;
  }
}
