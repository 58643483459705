.newsletter {
  &__title {
    font-size: 1.4rem;
    font-weight: 500;
    color: $c-black;
    margin: 0 0 2rem 0;
  }

  &__form {
    position: relative;
    width: 100%;
    height: 4rem;
  }

  &__input {
    font-family: $f-primary;
    font-size: 1.4rem;
    font-weight: 400;
    color: $c-black;
    width: inherit;
    height: inherit;
    border: solid 0.1rem $c-grey-2;
    padding: 0 3.6rem 0 1rem;
  }

  &__submit {
    position: absolute;
    background: $c-secondary;
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 2.4rem;
    border: none;
    box-shadow: none;
    cursor: pointer;
    top: 0.8rem;
    right: 0.8rem;
    @extend %anim2;

    &:hover, &:focus {
      background: darken($c-secondary, 8%);
      transform: rotate(10deg);
    }

    i {
      font-size: 0.8rem;
      color: $c-white;
    }
  }

  &__error {
    display: block;
    font-size: 1.2rem;
    font-weight: 500;
    color: $c-secondary;
    margin: 1rem 0 0 0;
  }

  // placeholder

  ::-webkit-input-placeholder { font-family: $f-primary; font-size: 1.4rem; font-weight: 400; color: $c-grey-3; }
  ::-moz-placeholder { font-family: $f-primary; font-size: 1.4rem; font-weight: 400; color: $c-grey-3; }
  :-ms-input-placeholder { font-family: $f-primary; font-size: 1.4rem; font-weight: 400; color: $c-grey-3; }
  :-moz-placeholder { font-family: $f-primary; font-size: 1.4rem; font-weight: 400; color: $c-grey-3; }
}
