.hero__footer {
  position: absolute;
  background: $c-primary;
  text-align: center;
  width: 100%;
  height: 6rem;
  bottom: 0;

  @include respond-to(small) {
    display: none;
  }

  &:before {
    position: absolute;
    background-image: url('../img/brand/ornament-1.svg');
    background-position: center bottom -0.2rem;
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    height: 16rem;
    right: -1rem;
    bottom: 100%;
    left: -1rem;
  }

  &__caret {
    cursor: pointer;
    padding: 1rem;

    i {
      font-size: 1rem;
      color: $c-white;
    }
  }

  &__timer {
    position: absolute;
    margin: 0 auto;
    top: -8rem;
    right: 0;
    left: 0;

    &__inner {
      display: inline-block;
      background: $c-white;
      padding: 1.8rem;
    }

    &__unit {
      font-size: 1.6rem;
      font-weight: 700;
      color: $c-black;

      &--primary i { background: $c-primary; }
      &--secondary i { background: $c-secondary; }

      i {
        display: inline-block;
        color: $c-white;
        font-style: normal;
        width: 2.8rem;
        padding: 0.1rem 0.4rem 0.1rem 0.3rem;
      }
    }
  }
}
