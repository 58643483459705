.tiles {
  &--offset {
    margin: 6rem 0 0 0;

    @include respond-to(small) {
      margin: 2rem 0 0 0;
    }

    @include respond-to(xsmall) {
      margin: 0;
    }
  }

  > * {
    margin-bottom: 2rem;

    @include respond-to(small) {
      margin-bottom: 0;
    }
  }
}

@import "tiles-separator";
@import "tiles-filter";
@import "tile";
