.nav__hamburger {
  display: none;
  background: none;
  font-size: 1.6rem;
  border: none;
  color: $c-white;

  @include respond-to(small) {
    display: block;
  }
}
