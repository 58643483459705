.activities {
  @include respond-to(small) {
    display: none;
  }

  &--offset {
    position: relative;
    margin: -6rem 0 0 0;
    z-index: 3;
  }

  &__content {
    position: relative;
    background: $c-white;
    width: 100%;
    height: 48rem;
    border-bottom-right-radius: 1rem;
    box-shadow: 0px 0px 4rem 0px rgba(0,0,0,0.08);
    border: solid 0.8rem $c-white;
  }

  &__title {
    position: absolute;
    background: $c-white;
    font-size: 1.8rem;
    font-weight: 700;
    color: $c-black;
    box-shadow: 0px 0px 2rem 0px rgba(0,0,0,0.08);
    padding: 1rem 1.4rem;
    top: -2.6rem;
    left: 4rem;
    z-index: 2;
  }

  &__button {
    position: absolute;
    right: 2rem;
    bottom: -3rem;
    z-index: 2;
  }

  &__map {
    position: absolute;
    background: $c-grey-2;
    @extend %abs-full;
  }

  &__tile {
    position: absolute;
    top: 2.2rem;
    right: 2.2rem;
    z-index: 2;
  }
}
