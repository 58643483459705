
.region {
  margin:0 auto;
  padding: 4rem 0 7rem;
  clear: both;
  @extend %clearfix;

  @include respond-to(small) {
    padding: 3rem 0 3rem;
  }
}



.region:not(.region--alt) + .region:not(.region--alt) {
  padding-top: 0;
}

// color variants
.region--alt {
  background-color: $alt-region-bg;
  //  padding-bottom: 3rem;
}

// two consequent different regions
.region:not(.region--alt) + .region--alt, .region--alt + .region:not(.region--alt) {
  //  margin-top: 5rem;
  padding-top: 7rem;

  @include respond-to(small) {
    //    margin-top: 3rem;
    padding-top: 4rem;
  }
}

// .region:first-child {
//   padding-top: 7rem;
//   @include respond-to(small) {
//     padding-top: 3rem;
//   }
// }


.region--tiny {
  padding: 3rem 0;
  @include respond-to(small) {
    padding: 2rem 0;
  }
  @include respond-to(xsmall) {
    padding: 1.5rem 0;
  }
}

.region--small {
  padding: 4rem 0;
  @include respond-to(small) {
    padding: 3rem 0;
  }
  @include respond-to(xsmall) {
    padding: 2rem 0;
  }
}

.region--medium {
  padding: 8rem 0;

  @include respond-to(small) {
    padding: 4rem 0;
  }
  @include respond-to(xsmall) {
    padding: 2rem 0;
  }
}

.region--large {
  padding: 12rem 0;

  @include respond-to(small) {
    padding: 4rem 0;
  }
}

.region--huge {
  padding: 16rem 0;
  @include respond-to(medium) {
    padding: 12rem 0;
  }
  @include respond-to(small) {
    padding: 8rem 0;
  }
  @include respond-to(xsmall) {
    padding: 4rem 0;
  }
}







.region--bordered + .region--bordered {
  border-top: 1px solid $alt-region-bg;

  &.region--alt {
    border-top-color: #fff;
  }
}



.region--no-space {
  padding: 0 !important;
}

.region--no-space-bottom {
  padding-bottom: 0 !important;
}

@include respond-to(small) {
  .region--no-space-bottom--s {
    padding-bottom: 0 !important;
  }
}

.region--no-space-top {
  padding-top: 0 !important;
}
