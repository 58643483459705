.details {
  margin: 6rem 0 0 2rem;

  @include respond-to(small) {
    margin: 4rem 0 0 0;
  }

  @include respond-to(xsmall) {
    margin: 2rem 0 0 0;
  }

  &__type {
    position: relative;
    font-size: 2.2rem;
    font-weight: 700;
    line-height: 2.8rem;
    color: $c-black;
    margin: 0 0 4rem 0;

    @include respond-to(xsmall) {
      margin: 0 0 2rem 0;
    }

    &:before {
      position: absolute;
      background: $c-secondary;
      content: '';
      width: 10rem;
      height: 0.4rem;
      border-radius: 0.4rem;
      top: 0.8rem;
      right: 105%;
    }
  }

  &__header {
    display: flex;

    @include respond-to(xsmall) {
      display: block;
    }
  }

  &__title {
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 2.4rem;
    color: $c-grey-3;
  }

  &__time {
    position: relative;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 2rem;
    color: $c-grey-3;
    margin: 0 0 0 2rem;

    @include respond-to(xsmall) {
      margin: 0;
    }

    &:before {
      position: absolute;
      background: $c-secondary;
      content: '';
      height: 0.2rem;
      border-radius: 0.4rem;
      right: 0;
      bottom: -0.2rem;
      left: 0;
    }
  }

  &__typography {
    margin: 2rem 0;

    p {
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 2.4rem;
      color: $c-grey-3;
      margin: 0 0 1rem 0;
    }

    ul {
      li {
        position: relative;
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 2.4rem;
        color: $c-grey-3;
        list-style: none;
        padding: 0 0 0 2rem;
        margin: 0 0 1rem 0;

        &:last-child {
          margin: 0;
        }

        &:before {
          position: absolute;
          background: $c-primary;
          content: '';
          width: 0.6rem;
          height: 0.6rem;
          top: 1rem;
          left: 0;
        }
      }
    }
  }

  &__share {
    display: flex;
    align-items: center;
    margin: 6rem 0 0 0;

    @include respond-to(small) {
      margin: 2rem 0 0 0;
    }

    &__title {
      font-size: 1.5rem;
      font-weight: 500;
      color: $c-black;
      line-height: 2.4rem;
    }

    &__list {
      display: flex;
    }

    &__item {
      list-style: none;
      margin: 0 0 0 1.8rem;
    }

    &__cta {
      display: block;
      font-size: 1.8rem;
      color: $c-white;
      width: 4rem;
      height: 4rem;
      line-height: 4rem;
      text-align: center;
      text-decoration: none;
      box-shadow: 0 0 2rem 0 rgba(0,0,0,0.08);
      @extend %anim2;

      &:hover, &:focus {
        transform: scale(1.04);
      }

      .details__share__item:nth-child(1) & { background: $c-facebook; }
      .details__share__item:nth-child(2) & { background: $c-twitter; }
    }
  }
}
