.button {
  display: inline-block;
  font-family: $f-primary;
  font-size: 1.4rem;
  font-weight: 700;
  color: $c-grey-3;
  cursor: pointer;
  border: none;
  text-decoration: none;
  clip-path: polygon(0 8%, 98% 0, 100% 100%, 0 92%);
  padding: 0.8rem 1.2rem;
  @extend %anim2;

  &:hover, &:focus {
    clip-path: polygon(2% 14%, 96% 0, 100% 100%, 0 100%);
  }

  // styles primary

  &--primary {
    background: $c-primary;
    color: $c-white;

    &-light {
      background: lighten($c-primary, 6%);
      color: $c-white;
    }
  }

  // styles secondary

  &--secondary {
    background: $c-secondary;
    color: $c-white;

    &-light {
      background: lighten($c-secondary, 10%);
      color: $c-white;
    }
  }

  // styles white

  &--white {
    background: $c-white;
    color: $c-black;
  }
}
